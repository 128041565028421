*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
    text-align: center;
}

.visuals-vertical{
    height: 500px;
    width: 300px;
}

.visuals-horizontal {
    height: 300px;
    width: 500px;
}

.visuals-squareBig {
    height: 500px;
    width: 500px;
}

.visuals-squareSmall {
    height: 300px;
    width: 300px;
}


.projects {
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: var(--light-gray);
    
    text-align: center;
}

/*Format for most of the projects */
.project-grid{
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 1fr 2fr 1fr;
    text-align: center;

    padding-block: 2rem;
    width: min(95%, 70rem);
    margin-inline: auto;
}

@media only screen and (max-width: 1200px) {
    .project-grid {
        display: grid;
        gap: 1.5rem;
        grid-template-columns: 1fr;
        text-align: center;

        padding-block: 2rem;
        width: min(95%, 70rem);
        margin-inline: auto;
    }
    .visuals-horizontal {
        height: 300px;
        width: 350px;
    }
    .visuals-squareBig{
        height: 350px;
        width: 350px;
    }
}


/*For the picture on the right of cruuz*/
.cruuzWeb {
    height: 600px;
    width: 300px;
}
/*For the picture on the left of cruuz */
.cruuzLogo{
    height: 200px;
    width: 200px;
}
/*Format for portfolio section */
.portfolio{
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 10% 80% 10%;
    text-align: center;

    padding-block: 2rem;
    width: min(95%, 70rem);
    margin-inline: auto;
}


/* CURRENTLY UNUSED
Format for the empty pcb picture 
.pcbEmpty{
    transform: rotate(-90deg);
    height: 260px;
    width: 200px;
}
.pcbAssembled{
    height: 200px;
    width: 200px;
}
*/
.animatronicWebpage{
    height: 500px;
    width: 300px;
}
/*the title above each project */
.label{
    color:var(--gray5);
    font-size: 25px;
}
.position{
    font-size: 20px;
    color: var(--gray1);
}

/*description for each project */
.description{
    color:var(--gray1);
    gap: 1.5rem;
    text-align: center;

    padding-block: 2rem;
    width: min(95%, 70rem);
    margin-inline: auto;

}
/*labels to seperate project types*/
.type{
    /*color:var(--blue-green);*/
    color: var(--french-pass);
}
