*,
*::before,
*::after {
  box-sizing: border-box;
}



html, body {
  margin: 0;
  font-family: 'Trebuchet MS', sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: var(--light-gray);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--not-black);
  scroll-behavior: smooth;
}

.icon {
  font-size: 40px;
  color: var(--light-blue);
}

.title {
  color: var(--french-pass);
}

.home-grid {
  display: flex;
  
  gap: 3rem;
  padding-block: 2rem;
  width: min(90%, 70rem);
  margin-inline: auto;

  
}

@media only screen and (max-width: 1200px) {
  .home-grid {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 1fr;
    text-align: center;
    align-self: center;
  
    padding-block: 2rem;
    width: min(95%, 70rem);
    margin-inline: auto;
  }

  .image {
    align-items: center;
    justify-self: center;
  }
}

.profile{
  margin: auto;
  padding: 10px;
  width: 100%;
}

.image {
  height: 50%;
  width: 50%;
}


