*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --blue-green: #2b6777; /* use for main background */
  --green: #52ab98;
  --white: #ffffff;
  --light-gray: #f2f2f2;
  --light-blue: #c8d8e4;

  /*grays*/
  --gray1: #999999;
  --gray2: #777777;
  --gray3: #555555;
  --gray4: #333333;
  --black: #111111;

  /*discord color palette */
  --not-black: #23272A;
  --dark: #2c2F33;
  --greyple: #99AAB5;
}

html, body {
    margin: 0;
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: var(--dark);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}
