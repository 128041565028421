*,
*::before,
*::after {
  box-sizing: border-box;
}


body {
    text-align: center;
}

.card-grid{
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;

  padding-block: 2rem;
  width: min(95%, 70rem);
  margin-inline: auto;
}

@media only screen and (max-width: 1200px) {
  .card-grid{
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr;
  text-align: center;

  }
  .card{
    background-color: var(--dark);
    margin: auto;
    width: 50%;
  }
  .coursework{
    width: auto;
    margin: 0, auto
  }
  
  
}

.card{
  background-color: var(--dark);
  margin: auto;
  width: 50%;
  padding: 10px;
}

.course-name{
  font-size: 16px;
  color: var(--gray5)
}

.class-description{
  font-size: 20px;
  color: var(--gray1);
}

.class-date{
  font-size: 18px;
  color: var(--gray1)
}

.class-link{
  height: 50px;
  width: 170px;
  
  margin: auto;
  width: 50%;
  padding: 10px;
}

.skills-projects{
  color: var(--light-gray)
}