*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
    --blue-green: #2b6777; /* use for main background */
    --green: #52ab98;

    --white: #ffffff;
    --light-gray: #f2f2f2;
    --light-blue: #0583D2;
    --dark-blue: #16558F;
    --tradewind: #61B0B7;
    --french-pass: #B8E3FF;

    /*grays*/
    --gray1: #DCDCDC;
    --gray2: #777777;
    --gray3: #555555;
    --gray4: #333333;
    --black: #111111;

    --header-gray: #ffffff8c;
    /*discord color palette */
    --not-black: #23272A;
    --dark: #2c2F33;
    --greyple: #99AAB5;
}
    

.name{
    font-weight: 400;
    font-size: 40px;
    color: var(--light-blue);
}

.header{
    color: var(--header-gray);
}



.navbar {
    background-color: var(--not-black);
}

