*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --blue-green: #2b6777; /* use for main background */
  --green: #52ab98;
  --white: #ffffff;
  --light-gray: #f2f2f2;
  --light-blue: #c8d8e4;

  /*grays*/
  --gray1: #999999;
  --gray2: #777777;
  --gray3: #555555;
  --gray4: #333333;
  --black: #111111;

  /*discord color palette */
  --not-black: #23272A;
  --dark: #2c2F33;
  --greyple: #99AAB5;
}

html, body {
    margin: 0;
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #2c2F33;
    color: var(--dark);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
    text-align: center;
}

.visuals-vertical{
    height: 500px;
    width: 300px;
}

.visuals-horizontal {
    height: 300px;
    width: 500px;
}

.visuals-squareBig {
    height: 500px;
    width: 500px;
}

.visuals-squareSmall {
    height: 300px;
    width: 300px;
}


.projects {
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: var(--light-gray);
    
    text-align: center;
}

/*Format for most of the projects */
.project-grid{
    display: grid;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    grid-template-columns: 1fr 2fr 1fr;
    text-align: center;

    padding-block: 2rem;
    width: min(95%, 70rem);
    margin-inline: auto;
}

@media only screen and (max-width: 1200px) {
    .project-grid {
        display: grid;
        grid-gap: 1.5rem;
        gap: 1.5rem;
        grid-template-columns: 1fr;
        text-align: center;

        padding-block: 2rem;
        width: min(95%, 70rem);
        margin-inline: auto;
    }
    .visuals-horizontal {
        height: 300px;
        width: 350px;
    }
    .visuals-squareBig{
        height: 350px;
        width: 350px;
    }
}


/*For the picture on the right of cruuz*/
.cruuzWeb {
    height: 600px;
    width: 300px;
}
/*For the picture on the left of cruuz */
.cruuzLogo{
    height: 200px;
    width: 200px;
}
/*Format for portfolio section */
.portfolio{
    display: grid;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    grid-template-columns: 10% 80% 10%;
    text-align: center;

    padding-block: 2rem;
    width: min(95%, 70rem);
    margin-inline: auto;
}


/* CURRENTLY UNUSED
Format for the empty pcb picture 
.pcbEmpty{
    transform: rotate(-90deg);
    height: 260px;
    width: 200px;
}
.pcbAssembled{
    height: 200px;
    width: 200px;
}
*/
.animatronicWebpage{
    height: 500px;
    width: 300px;
}
/*the title above each project */
.label{
    color:var(--gray5);
    font-size: 25px;
}
.position{
    font-size: 20px;
    color: var(--gray1);
}

/*description for each project */
.description{
    color:var(--gray1);
    grid-gap: 1.5rem;
    gap: 1.5rem;
    text-align: center;

    padding-block: 2rem;
    width: min(95%, 70rem);
    margin-inline: auto;

}
/*labels to seperate project types*/
.type{
    /*color:var(--blue-green);*/
    color: var(--french-pass);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}


body {
    text-align: center;
}

.card-grid{
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;

  padding-block: 2rem;
  width: min(95%, 70rem);
  margin-inline: auto;
}

@media only screen and (max-width: 1200px) {
  .card-grid{
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  grid-template-columns: 1fr;
  text-align: center;

  }
  .card{
    background-color: var(--dark);
    margin: auto;
    width: 50%;
  }
  .coursework{
    width: auto;
    margin: 0, auto
  }
  
  
}

.card{
  background-color: var(--dark);
  margin: auto;
  width: 50%;
  padding: 10px;
}

.course-name{
  font-size: 16px;
  color: var(--gray5)
}

.class-description{
  font-size: 20px;
  color: var(--gray1);
}

.class-date{
  font-size: 18px;
  color: var(--gray1)
}

.class-link{
  height: 50px;
  width: 170px;
  
  margin: auto;
  width: 50%;
  padding: 10px;
}

.skills-projects{
  color: var(--light-gray)
}
*,
*::before,
*::after {
  box-sizing: border-box;
}



html, body {
  margin: 0;
  font-family: 'Trebuchet MS', sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: var(--light-gray);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--not-black);
  scroll-behavior: smooth;
}

.icon {
  font-size: 40px;
  color: var(--light-blue);
}

.title {
  color: var(--french-pass);
}

.home-grid {
  display: flex;
  
  grid-gap: 3rem;
  
  gap: 3rem;
  padding-block: 2rem;
  width: min(90%, 70rem);
  margin-inline: auto;

  
}

@media only screen and (max-width: 1200px) {
  .home-grid {
    display: grid;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    grid-template-columns: 1fr;
    text-align: center;
    align-self: center;
  
    padding-block: 2rem;
    width: min(95%, 70rem);
    margin-inline: auto;
  }

  .image {
    align-items: center;
    justify-self: center;
  }
}

.profile{
  margin: auto;
  padding: 10px;
  width: 100%;
}

.image {
  height: 50%;
  width: 50%;
}



*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
    --blue-green: #2b6777; /* use for main background */
    --green: #52ab98;

    --white: #ffffff;
    --light-gray: #f2f2f2;
    --light-blue: #0583D2;
    --dark-blue: #16558F;
    --tradewind: #61B0B7;
    --french-pass: #B8E3FF;

    /*grays*/
    --gray1: #DCDCDC;
    --gray2: #777777;
    --gray3: #555555;
    --gray4: #333333;
    --black: #111111;

    --header-gray: #ffffff8c;
    /*discord color palette */
    --not-black: #23272A;
    --dark: #2c2F33;
    --greyple: #99AAB5;
}
    

.name{
    font-weight: 400;
    font-size: 40px;
    color: #0583D2;
    color: var(--light-blue);
}

.header{
    color: #ffffff8c;
    color: var(--header-gray);
}



.navbar {
    background-color: #23272A;
    background-color: var(--not-black);
}


